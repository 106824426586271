import Cookies from 'js-cookie'
import Axios from 'axios'
import { isNil } from 'lodash'
// import jsonWebToken from 'jsonwebtoken'

const getTempoExpiracao = () => {
  const date = new Date()
  date.setSeconds(date.getSeconds() + 43200)
  return date
}

export default {
  saveCookie: token => {
    // const jwtDecrypted = jsonWebToken.decode(token)
    Cookies.set('user_token', token, { expires: getTempoExpiracao() })
  },
  removeCookie: () => {
    Cookies.remove('user_token')
  },
  login: async ({ username, password, id_empresa, express }) => {
    const params = { username, password, id_empresa, express }
    return Axios.post('/login', params, { withCredentials: true })
  },
  async register({ usuario, senha, email, cargo, cpf, fone }) {
    try {
      const params = { usuario, senha, email, cargo, cpf, fone }
      const response = await Axios.post('/agendamento-express/usuarios', params)
      return response
    } catch (e) {
      return Promise.reject(e)
    }
  },
  isAuthenticated: () => !isNil(Cookies.get('user_token')),
  getToken: () => Cookies.get('user_token'),
  enviarTokenEmail: email => {
    return Axios.post('/agendamento-express/token/email', { email })
  },
  confirmarToken: tokenEmail => {
    return Axios.post('/agendamento-express/token/confirmar', { tokenEmail })
  },
}
