<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-15 registro-form">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="head-title mb-2 text-center"> Entre no seu perfil </v-col>
            <v-col cols="12" class="subtitle text-center"> Precisamos de algumas informações sobre você </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-form ref="form">
        <div class="d-flex flex-column justify-center">
          <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Seu email:</div>
          <v-text-field
            label="Email da sua conta"
            outlined
            v-model="usuario.username"
            :rules="[
              () => $v.usuario.username.required || 'Campo obrigatório',
              () => $v.usuario.username.email || 'E-mail inválido',
            ]"
          ></v-text-field>
          <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Sua senha:</div>
          <v-text-field
            label="Senha da sua conta"
            outlined
            v-model="usuario.password"
            :type="!showPassword ? 'password' : 'text'"
            @click:append="showPassword = !showPassword"
            :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[() => $v.usuario.password.required || 'Campo obrigatório']"
          ></v-text-field>
          <v-alert type="error" v-show="hasErrorInService">
            {{ errorMessage }}
          </v-alert>
        </div>
      </v-form>
      <v-row no-gutters justify="center" class="ma-2">
        <v-btn color="primary" rounded x-large class="elevation-12" @click="submit" :loading="loading">
          <span class="capitalize">Entrar</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters justify="center" class="ma-2">
        <v-btn class="ma-2 primary--text" to="/recuperar-senha" text>Esqueci minha senha</v-btn>
      </v-row>
      <v-row no-gutters justify="center" class="ma-2">
        <v-btn
          color="primary"
          rounded
          x-large
          class="elevation-12"
          :disabled="enable_agendamento_express"
          @click="toCadastro"
          :loading="loading"
        >
          <span class="capitalize">Cadastre-se</span>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import autenticacaoService from '@/services/autenticacao'
import agendamentoMixin from '@/mixins/agendamentoMixin'

export default {
  mixins: [validationMixin, agendamentoMixin],
  validations: {
    usuario: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  data() {
    return {
      usuario: {
        username: null,
        password: null,
      },
      showPassword: false,
      loading: false,
      hasErrorInService: null,
      errorMessage: '',
    }
  },
  props: {
    id_empresa: [String, Number],
    enable_agendamento_express: [Boolean],
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return

      this.hasErrorInService = false
      this.loading = true
      try {
        const { data } = await autenticacaoService.login({
          ...this.usuario,
          id_empresa: this.enable_agendamento_express ? this.id_empresa : null,
          express: true,
        })
        await autenticacaoService.saveCookie(data.token)
        this.$emit('next-step')
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.hasErrorInService = true
        if (
          error.response.status === 409 &&
          error.response.data.message === 'Usuário precisa confirmar código de verificação de email'
        ) {
          this.$router.push({ name: 'confirmacao-email' })
        }
      } finally {
        this.loading = false
      }
    },
    async toCadastro() {
      this.$emit('on-cadastro')
    },
  },
}
</script>

<style scoped>
.registro-form {
  padding: 0px 20%;
}
.highlighted:hover {
  text-decoration: underline;
}
</style>
